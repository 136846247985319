import { defineStore } from 'pinia'

import type { AppWarmup } from '../types'

type AppStoreState = {
  loader: boolean
  ready: boolean
  warmup: AppWarmup
}

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => ({
    loader: true,
    ready: false,
    warmup: {
      nodeUrl: undefined,
      webRtc: undefined,
      uploadPhotoPk: undefined,
      uploadPhotoUrl: undefined,
    },
  }),
  getters: {},
  actions: {},
})
